import { Typography, Box, SvgIcon } from "@material-ui/core";
import { TouchableOpacity, View } from "react-native-web";
import _ from "lodash";
import { Org, OrgInvoiceParent, OrgInvoiceTypes, OrgRegistrationTeamSummary } from "@ollie-sports/models";
import { CenteredLoader } from "../../components/CenteredLoader";
import { dateFormatters, getCurrentLocale, translate } from "@ollie-sports/i18n";
import { useLocation, useParams } from "react-router-dom";
import { useOrg } from "../../hooks/useOrg";
import { getBifrost } from "../../services/bifrost.service";
import { COLORS, formatMoneyCentsToDollarCentPrettyString } from "@ollie-sports/core";
import { useEffect, useRef, useState } from "react";
import { ActionButtonDropdown } from "../../components/ActionButtonDropdown";
import { MoreHorizontal } from "react-feather";
import { AsyncFancyTable, AsyncFancyTableMethods } from "../../components/AsyncFancyTable";
import moment from "moment";
import { CoolDateInput } from "../../components/Inputs/CoolDateInput";
import { BackButton } from "../../components/BackButton";

export default function OrgReportsCouponUsage() {
  const params: any = useParams();
  const orgId = params.orgId;

  const { org, isLoading } = useOrg({ orgId });

  return (
    <Box px={3} py={2} display="flex" style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        {isLoading ? (
          <CenteredLoader />
        ) : org ? (
          <OrgReportsCouponUsageInner org={org} />
        ) : (
          <Typography>{translate({ defaultMessage: "Failed to load club" })}</Typography>
        )}
      </View>
    </Box>
  );
}

function OrgReportsCouponUsageInner(p: { org: Org }) {
  const asyncFancyTableRef = useRef<AsyncFancyTableMethods<any>>(null);

  return (
    <div style={{ flex: 1 }}>
      <BackButton />
      <div className="flex">
        <h1 className="flex-1 text-2xl sm:text-4xl mt-4">{translate({ defaultMessage: "Invoice Coupon Usage Report" })}</h1>
        <ActionButtonDropdown
          style={{ marginLeft: 12 }}
          color="secondary"
          actions={[
            {
              key: "csv-export",
              label: () =>
                translate(
                  { defaultMessage: "Export {num} Records to CSV" },
                  { num: asyncFancyTableRef.current?.getCurrentNumRecords() || 0 }
                ),
              onClick: () => {
                asyncFancyTableRef.current?.downloadCurrentDataToCSV("coupon-usage.csv");
              }
            }
          ]}
        >
          <span style={{ fontWeight: "bold", marginRight: 6 }}>{translate({ defaultMessage: "Actions" })}</span>
          <SvgIcon style={{ paddingRight: 6 }}>
            <MoreHorizontal />
          </SvgIcon>
        </ActionButtonDropdown>
      </div>
      <AsyncFancyTable
        methodsRef={asyncFancyTableRef}
        pagination={{ initialPageSize: 50, pageSizes: [50, 100, 150] }}
        getRowOptions={(item: {
          checkout_date_ms: number;
          code: string;
          discount_cents: number;
          player: string;
          team: string | null;
          has_in_progress_payment_plan: boolean;
          org_invoice: OrgInvoiceParent;
          count: number;
        }) => {
          return {
            href: `/app/org/${p.org.id}/invoices/${item.org_invoice.id}`
          };
        }}
        className="mb-10"
        getRowKey={item => item.org_invoice.id}
        dataCachingKey={"coupon-usage-" + p.org.id}
        noItemsMessage={translate({ defaultMessage: "No coupons used..." })}
        noFilteredItemsMessage={translate({ defaultMessage: "No coupons found..." })}
        fetchItems={info => {
          return getBifrost()
            .reports__server__getOrgCouponUsage.fetchServer({
              orgId: p.org.id,
              page: info.pagination.page,
              numItemsPerPage: info.pagination.numItemsPerPage,
              startDateMS: info.filters?.startMS,
              endDateMS: info.filters?.endMS
            })
            .then(a => {
              return {
                itemsToBeRendered: a.data.itemsToBeRendered,
                totalNumberOfItemsMatchingCriteria: a.data.totalNumberOfItemsMatchingCriteria
              };
            });
        }}
        columns={{
          coupon: {
            label: translate.common.Code,
            getCell(item) {
              return item.code;
            }
          },
          type: {
            label: translate.common.Type,
            getCell: item =>
              item.org_invoice.type === OrgInvoiceTypes.registration ? translate.common.Registration : translate.common.Invoice
          },
          player_name: {
            label: translate.common.Player,
            getCell(item) {
              return <div>{item.player}</div>;
            }
          },
          discount: {
            label: translate.common.Discount,
            getCell(item) {
              return formatMoneyCentsToDollarCentPrettyString(item.discount_cents);
            }
          },
          total_paid: {
            label: translate.common.Paid,
            getCell(item) {
              return item.team;
            }
          },
          created_at: {
            label: translate({ defaultMessage: "Checkout Date" }),
            getCell(item) {
              return dateFormatters.mm_dd_yyyy(item.checkout_date_ms);
            }
          },
          completed: {
            label: translate({ defaultMessage: "Complete" }),
            infoTooltip: translate({
              defaultMessage:
                "Whether there is still an in progress payment plan for the product (invoice or registration) purchased."
            }),
            getCell(item) {
              return item.has_in_progress_payment_plan ? translate.common.Yes : "";
            }
          }
        }}
        renderFiltersWrapper={filters => <div className="flex flex-wrap gap-1.5 mt-4 mb-2">{filters}</div>}
        renderFilters={{
          startMS: a => (
            <CoolDateInput
              className="mb-2"
              value={a.value ? moment(a.value).toDate() : undefined}
              placeholderText={translate({ defaultMessage: "Start Date" })}
              isClearable
              openToDate={moment().subtract(1, "year").toDate()}
              onChange={newVal => {
                a.setValue(moment(newVal).startOf("day").valueOf());
              }}
            />
          ),
          endMS: a => (
            <CoolDateInput
              className="mb-2"
              value={a.value ? moment(a.value).toDate() : undefined}
              placeholderText={translate({ defaultMessage: "End Date" })}
              isClearable
              onChange={newVal => {
                a.setValue(moment(newVal).endOf("day").valueOf());
              }}
            />
          )
        }}
        initialFilterValues={{
          startMS: undefined as number | undefined,
          endMS: undefined as number | undefined
        }}
      />
    </div>
  );
}
