import { Box, Typography, Button, SvgIcon } from "@material-ui/core";
import {
  AccountId,
  ORG_PERMISSIONS,
  Org,
  OrgInvoice,
  OrgInvoiceChild,
  OrgInvoiceParent,
  OrgInvoiceTypes,
  OrgPayment,
  OrgPaymentInvoice,
  OrgPaymentInvoiceCredit,
  OrgPaymentInvoiceDefault,
  OrgPaymentInvoiceFailed,
  OrgPaymentInvoiceFailedECheck,
  OrgPaymentRefund,
  OrgPaymentType,
  OrgRegistration,
  OrgRegistrationAnswer,
  OrgRegistrationAnswerAdditionalStep,
  OrgRegistrationQuestionType,
  OverallInvoiceStatus,
  PaymentMethodType,
  PlayerBundle,
  PlayerBundle__AccountType,
  PrettyPlayer
} from "@ollie-sports/models";
import { Tooltip } from "@material-ui/core";
import { Link, useParams, useHistory } from "react-router-dom";
import _, { update } from "lodash";
import { useOrg } from "../../hooks/useOrg";
import { getBifrost } from "../../services/bifrost.service";
import { dateFormatters, getCurrentLocale, translate } from "@ollie-sports/i18n";
import { TouchableOpacity, View } from "react-native-web";
import { CenteredLoader } from "../../components/CenteredLoader";
import CoolerTable from "../../components/CoolerTable";

import {
  formatMoneyCentsToDollarCentPrettyString,
  getOverallOrgInvoiceAmountDetails,
  getIndividualOrgInvoiceAmountDetails,
  COLORS,
  isIndividualOrgInvoiceEligibleForCreditDeletion,
  PRETTY_OVERALL_ORG_INVOICE_STATUS,
  getRefundAvailabilityDetails,
  compute,
  getPaymentMethodString,
  api,
  PRETTY_PAYMENT_ERROR_CODES
} from "@ollie-sports/core";
import moment from "moment";
import { StyledText } from "../../components/StyledText";
import {
  ArrowLeftCircleIcon,
  ArrowPathIcon,
  ArrowUpCircleIcon,
  CurrencyDollarIcon,
  EyeIcon,
  InformationCircleIcon,
  PlusCircleIcon,
  ReceiptRefundIcon,
  TrashIcon
} from "@heroicons/react/24/outline";
import {
  openOrgPaymentInvoiceCreditAddModal,
  openOrgPaymentInvoiceCreditAddToScheduledPaymentModal
} from "./OrgPaymentInvoiceCreditAdd";
import getFullScreenModal from "../../components/modals/getFullscreenModal";
import { openErrorToast, openSuccessToast, openWarningToast } from "../../utils/openErrorToast";
import getAlert from "../../components/modals/getAlert";
import getConfirm from "../../components/modals/getConfirm";
import { wrapPromiseWithLoader } from "../../utils/wrapPromiseWithLoader";
import { PrettyCoolDateInput, PrettyCoolSelectInput, PrettyCoolTextInput } from "../../components/Form";
import { ShadowView } from "../../components/ShadowView";
import { ArrowDown, Paperclip } from "react-feather";
import { openPaymentRefundModal } from "../../utils/openPaymentRefundModal";
import { OrgRegistrationAnswersTable } from "./components/OrgRegistrationAnswersTable";
import { BackButton } from "../../components/BackButton";
import { getCurrentUserAccountId } from "../../hooks/commonDataUtils";
import { ReactNode, useEffect } from "react";
import { useAccounts } from "../../hooks/useAccounts";
import { InfoTooltip, InfoTooltipIcon } from "../../components/InfoTooltip";
import { KeyboardArrowDown, More } from "@material-ui/icons";
import { ActionButtonDropdown } from "../../components/ActionButtonDropdown";
import { openOrgInvoiceAddEditModal } from "./OrgInvoiceAddEdit";
import { TableSectionWrapper } from "./components/TableSectionWrapper";
import { StyledButton } from "../../components/StyledButton";
import { openOrgPaymentInvoiceAddInstallmentModal } from "./OrgPaymentInvoiceAddInstallment";
import { openOrgPaymentInvoiceChargeNowModal } from "./OrgPaymentInvoiceChargeNow";
import { useOrgSettings } from "../../hooks/useOrgSettings";
import { getDialogConfirm } from "../../components/DialogConfirm";
import { openOrgRegistrationDeleteConfirm } from "./OrgRegistrationDeleteConfirm";

export default function OrgInvoiceDetails() {
  const params: any = useParams();
  const orgInvoiceId = params.orgInvoiceId;
  const playerId = params.playerId;

  const {
    data: orgInvoiceData,
    isLoading: isLoadingOrgInvoiceData,
    refetch
  } = getBifrost().orgInvoice__client__getParentOrgInvoiceAndAllRelatedInvoicesAndPayments.useClient(
    { id: orgInvoiceId },
    { notifyOnMetaDataChanges: true }
  );

  const orgId = params.orgId || orgInvoiceData?.parentOrgInvoice.orgId;

  const { org, isLoading: isLoadingOrg } = useOrg({ orgId, enabled: !!orgId });

  const { data: prettyPlayer } = getBifrost().player__server__getPrettyPlayer.useServer(
    {
      playerId
    },
    { enabled: !!playerId }
  );

  const { data: orgRegistration } = getBifrost().orgRegistration__client__getOrgRegistrationForOrgInvoice.useClient(
    {
      orgId: orgId || "",
      orgRegistrationInvoiceId:
        orgInvoiceData?.parentOrgInvoice.type === OrgInvoiceTypes.registration ? orgInvoiceData.parentOrgInvoice.id : ""
    },
    { enabled: orgInvoiceData?.parentOrgInvoice.type === OrgInvoiceTypes.registration }
  );

  return (
    <Box px={3} py={2} display="flex" style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        {isLoadingOrg || isLoadingOrgInvoiceData ? (
          <CenteredLoader />
        ) : org && orgInvoiceData ? (
          <OrgInvoiceDetailsInner
            org={org}
            parentOrgInvoice={orgInvoiceData.parentOrgInvoice}
            childrenOrgInvoices={orgInvoiceData.childrenOrgInvoices}
            orgPayments={orgInvoiceData.orgPayments}
            orgRefunds={orgInvoiceData.orgRefunds}
            playerBundle={orgInvoiceData.playerBundle}
            onRefetch={async () => {
              await refetch();
            }}
            prettyPlayer={prettyPlayer}
            orgRegistration={orgRegistration}
          />
        ) : (
          <Typography>{translate({ defaultMessage: "Failed to load details" })}</Typography>
        )}
      </View>
    </Box>
  );
}

function OrgInvoiceDetailsInner(p: {
  org: Org;
  parentOrgInvoice: OrgInvoiceParent;
  childrenOrgInvoices: OrgInvoiceChild[];
  orgRefunds: OrgPaymentRefund[];
  orgPayments: OrgPaymentInvoice[];
  playerBundle: PlayerBundle;
  onRefetch: () => Promise<void>;
  prettyPlayer?: PrettyPlayer;
  orgRegistration?: OrgRegistration;
}) {
  const { data: registrationAnswers, refetch: refetchAnswers } =
    getBifrost().orgRegistrationAnswer__client__getAnswersForOrgInvoiceRegistration.useClient(
      {
        orgInvoiceId: p.parentOrgInvoice.id,
        orgId: p.parentOrgInvoice.orgId
      },
      { enabled: p.parentOrgInvoice.type === OrgInvoiceTypes.registration, notifyOnMetaDataChanges: true }
    );

  const { data: additionalSteps } = getBifrost().orgRegistrationQuestion__server__getAdditionalStepsForOrgRegistration.useClient(
    {
      orgRegistrationId: p.orgRegistration?.id ?? "",
      orgRegistrationPackageId:
        p.parentOrgInvoice.type === OrgInvoiceTypes.registration ? p.parentOrgInvoice.orgRegistrationPackageId : ""
    },
    { enabled: p.parentOrgInvoice.type === OrgInvoiceTypes.registration && !!p.orgRegistration }
  );

  const managingAccountIds = Object.keys(p.playerBundle.managingAccounts || {});

  const { data: allPossiblePlayerPaymentMethods } = getBifrost().accountSecret__server__getPaymentMethodsForAccountIds.useServer(
    { accountIds: managingAccountIds },
    { enabled: !!managingAccountIds.length }
  );

  const registrationAnswersExcludingAdditionalSteps = registrationAnswers?.filter(
    a => a.type !== OrgRegistrationQuestionType.additionalStep
  );

  const history = useHistory();
  const rowItemsBase: {
    orgInvoice: OrgInvoice;
    orgRefunds: OrgPaymentRefund[];
    orgPayments: OrgPaymentInvoice[];
  }[] = _.compact([p.parentOrgInvoice.amountDueCents > 0 ? p.parentOrgInvoice : null, ...p.childrenOrgInvoices])
    .map(invoice => {
      return {
        orgInvoice: invoice,
        // Payment TODO: Might replace this back to completedRefunds
        orgRefunds: p.orgRefunds.filter(r => r.orgInvoiceId === invoice.id),
        orgPayments: p.orgPayments.filter(op => op.invoiceId === invoice.id)
      };
    })
    .filter(a => (a.orgInvoice.id === p.parentOrgInvoice.id ? a.orgPayments.length > 0 : true));

  const { accounts: refundAccounts } = useAccounts({
    accountIds: _.uniq(p.orgRefunds.map(a => a.refundedByAccountId))
  });

  const paymentsTableItems = rowItemsBase.map(rib => {
    return {
      ...rib,
      ...getIndividualOrgInvoiceAmountDetails({
        orgInvoice: rib.orgInvoice,
        orgPayments: rib.orgPayments,
        paymentMethodType: PaymentMethodType.card // Doesn't matter here cause we don't show the fees
      })
    };
  });

  const overallDetails = getOverallOrgInvoiceAmountDetails({
    childrenOrgInvoices: p.childrenOrgInvoices,
    orgPayments: p.orgPayments,
    parentOrgInvoice: p.parentOrgInvoice
  });

  const creditGroupings: { credit: OrgPaymentInvoiceCredit; invoice: OrgInvoice; orgPayments: OrgPaymentInvoice[] }[][] = _(
    (p.orgPayments.filter(op => op.type === OrgPaymentType.invoiceCredit) as OrgPaymentInvoiceCredit[]).map(a => {
      const orgInvoice = [p.parentOrgInvoice, ...p.childrenOrgInvoices].find(oi => oi.id === a.invoiceId);
      if (orgInvoice) {
        return {
          invoice: orgInvoice,
          credit: a,
          orgPayments: p.orgPayments.filter(op => op.type === OrgPaymentType.invoiceDefault && op.invoiceId === orgInvoice.id)
        };
      }
    })
  )
    .compact()
    .groupBy(a => a.credit.groupingId)
    .orderBy(a => a[0].credit.createdAtMS, "desc")
    .value();

  const selfAthleteAccountId = Object.keys(p.playerBundle.managingAccounts || {}).find(
    accId => p.playerBundle.managingAccounts?.[accId]?.type === PlayerBundle__AccountType.selfAthlete
  );

  const { orgSettings } = useOrgSettings({ orgId: p.org.id });
  const selfAthleteAccountIdOrPlayerBundleId = selfAthleteAccountId ?? p.playerBundle.id;
  const parentInvoiceIsHandled = !!p.parentOrgInvoice.thisInvoicePaidInFullDateMS || !!p.childrenOrgInvoices.length;

  const actions = _.compact([
    !parentInvoiceIsHandled && p.parentOrgInvoice.type === OrgInvoiceTypes.manual
      ? {
          key: "edit",
          label: () => translate.common.Edit,
          onClick: async () => {
            openOrgInvoiceAddEditModal({
              org: p.org,
              type: "edit",
              initialOrgInvoice: p.parentOrgInvoice as any
            });
          }
        }
      : null,
    parentInvoiceIsHandled &&
    overallDetails.remainingAmount &&
    compute.org.hasOrgPermission({
      accountId: getCurrentUserAccountId(),
      org: p.org,
      permission: ORG_PERMISSIONS.manageFinances
    })
      ? {
          key: "credits",
          label: () => translate({ defaultMessage: "Add Credits" }),
          onClick: async () => {
            await openOrgPaymentInvoiceCreditAddModal({
              orgInvoiceParent: p.parentOrgInvoice,
              maxAmountCents: overallDetails.remainingAmount,
              scheduledChildrenOrgInvoices: p.childrenOrgInvoices.filter(oi => !oi.thisInvoicePaidInFullDateMS),
              orgPayments: p.orgPayments,
              onComplete: p.onRefetch
            });
          }
        }
      : null,
    parentInvoiceIsHandled &&
    overallDetails.remainingAmount &&
    p.childrenOrgInvoices.length &&
    compute.org.hasOrgPermission({
      accountId: getCurrentUserAccountId(),
      org: p.org,
      permission: ORG_PERMISSIONS.manageFinances
    })
      ? {
          key: "newInstallment",
          label: () => translate({ defaultMessage: "Add Installment" }),
          onClick: async () => {
            await openOrgPaymentInvoiceAddInstallmentModal({
              orgInvoiceParent: p.parentOrgInvoice,
              scheduledChildrenOrgInvoices: p.childrenOrgInvoices.filter(oi => !oi.thisInvoicePaidInFullDateMS),
              orgPayments: p.orgPayments,
              onComplete: p.onRefetch,
              exampleChildInvoice: p.childrenOrgInvoices[0]
            });
          }
        }
      : null,
    // Can only delete a manual registration if it hasn't been paid
    p.parentOrgInvoice.type === OrgInvoiceTypes.manual &&
    !p.parentOrgInvoice.thisInvoicePaidInFullDateMS &&
    !p.childrenOrgInvoices.length
      ? {
          key: "delete-manual",
          label: () => translate({ defaultMessage: "Delete Invoice" }),
          onClick: async () => {
            const confirm = await getConfirm({
              confirmButtonColor: "red",
              subtitle: translate({ defaultMessage: "Are you sure you want to delete this invoice?" }),
              title: translate({ defaultMessage: "Delete Invoice?" })
            });
            if (confirm) {
              await getBifrost().orgInvoice__client__deleteOrgInvoice.fetchClient({
                id: p.parentOrgInvoice.id,
                orgId: p.org.id
              });
              wrapPromiseWithLoader(
                new Promise(res => {
                  setTimeout(() => {
                    history.goBack();
                    res(null);
                  }, 2000);
                })
              );
            }
          }
        }
      : null,
    p.parentOrgInvoice.type === OrgInvoiceTypes.registration && p.orgRegistration
      ? {
          key: "delete-registration",
          label: () => translate({ defaultMessage: "Delete Registration" }),
          onClick: async () => {
            // Can only delete a registration if there are no outstanding children. Must be credited out
            if (overallDetails.remainingAmount > 0) {
              return getAlert({
                title: translate({ defaultMessage: "Can't Delete Registration" }),
                subtitle: translate({
                  defaultMessage:
                    "Can't delete a registration that has an outstanding balance. Please apply a credit equal to the remaining balance if you want to delete this registration."
                }),
                buttonColor: "red"
              });
            }
            openOrgRegistrationDeleteConfirm({
              orgId: p.org.id,
              orgRegistrationId: p.orgRegistration!.id,
              onComplete: async () => {
                history.goBack();
              },
              playerBundle: p.playerBundle
            });
          }
        }
      : null
  ]);

  return (
    <View style={{ flex: 1 }}>
      <BackButton />
      <View style={{ flexDirection: "row" }}>
        <h1 className="flex-1 text-2xl sm:text-4xl mt-4">
          {p.parentOrgInvoice.type === OrgInvoiceTypes.registration
            ? translate({ defaultMessage: "Registration Details" })
            : translate({ defaultMessage: "Invoice Details" })}
        </h1>
        {actions.length ? (
          <ActionButtonDropdown actions={actions}>
            {translate({ defaultMessage: "Actions" })}
            <SvgIcon style={{ paddingLeft: 6, fontSize: 30 }}>
              <KeyboardArrowDown />
            </SvgIcon>
          </ActionButtonDropdown>
        ) : null}
      </View>

      <ShadowView
        style={{
          backgroundColor: COLORS.white,
          padding: 16,
          borderRadius: 16,
          maxWidth: 400,
          marginTop: 16
        }}
      >
        <div className="flex mb-3 items-center">
          <StyledText style={{ fontWeight: "bold", fontSize: 20 }}>{"#" + p.parentOrgInvoice.id}</StyledText>
          <div
            style={{
              marginLeft: "auto",
              borderRadius: 8,
              padding: 6,
              backgroundColor: [
                OverallInvoiceStatus.invoiceFailedPayment,
                OverallInvoiceStatus.late,
                OverallInvoiceStatus.latePaymentInstallment
              ].includes(overallDetails.status)
                ? COLORS.red
                : overallDetails.status === OverallInvoiceStatus.paid
                ? COLORS.green
                : COLORS.yellow
            }}
          >
            <StyledText
              style={{
                fontFamily: "condensed-bold",
                color: "white",
                fontSize: 14
              }}
            >
              {PRETTY_OVERALL_ORG_INVOICE_STATUS(getCurrentLocale())[overallDetails.status].toUpperCase()}
            </StyledText>
          </div>
        </div>
        <div className="mb-4" style={{ height: 1, backgroundColor: COLORS.grey_33 }}></div>
        <OrgInvoiceDetailRow
          label={translate.common.Player}
          value={
            <Link to={`/app/org/${p.org.id}/registrations/members/${selfAthleteAccountIdOrPlayerBundleId}`} className="flex">
              <StyledText
                style={{ fontWeight: "bold", color: COLORS.blue, textDecoration: "underline" }}
              >{`${p.playerBundle.virtualAthleteAccount.firstName} ${p.playerBundle.virtualAthleteAccount.lastName}`}</StyledText>
            </Link>
          }
        />

        <OrgInvoiceDetailRow
          label={
            p.parentOrgInvoice.type === OrgInvoiceTypes.manual
              ? translate({ defaultMessage: "Issued On" })
              : translate({ defaultMessage: "Registration Date" })
          }
          value={dateFormatters.mmm_d_yyyy(p.parentOrgInvoice.createdAtMS)}
        />

        <OrgInvoiceDetailRow
          label={translate({ defaultMessage: "Invoiced Amount" })}
          value={formatMoneyCentsToDollarCentPrettyString(
            overallDetails.totalAmount + (p.parentOrgInvoice.appliedCouponCodeDetails?.discountAmountCents ?? 0)
          )}
        />
        <OrgInvoiceDetailRow
          label={translate({ defaultMessage: "Total Late Fees" })}
          value={formatMoneyCentsToDollarCentPrettyString(
            overallDetails.currentlyDueLateFeesAmount + overallDetails.pastPaidLateFeesAmount
          )}
        />
        {p.parentOrgInvoice.appliedCouponCodeDetails?.orgCouponCodeSnapshot ? (
          <OrgInvoiceDetailRow
            label={translate({ defaultMessage: "Coupon Code" })}
            value={p.parentOrgInvoice.appliedCouponCodeDetails.orgCouponCodeSnapshot}
          />
        ) : null}
        {p.parentOrgInvoice.appliedCouponCodeDetails ? (
          <OrgInvoiceDetailRow
            label={translate({ defaultMessage: "Coupon Discount" })}
            value={formatMoneyCentsToDollarCentPrettyString(p.parentOrgInvoice.appliedCouponCodeDetails.discountAmountCents)}
            type={"paymentOrCredit"}
          />
        ) : null}
        {overallDetails.creditAmount ? (
          <OrgInvoiceDetailRow
            label={translate({ defaultMessage: "Credit Amount" })}
            value={formatMoneyCentsToDollarCentPrettyString(overallDetails.creditAmount)}
            type={"paymentOrCredit"}
          />
        ) : null}
        <OrgInvoiceDetailRow
          label={translate({ defaultMessage: "Amount Paid" })}
          value={formatMoneyCentsToDollarCentPrettyString(
            overallDetails.paidAmount + (overallDetails.pastPaidLateFeesAmount || 0)
          )}
          type={"paymentOrCredit"}
        />
        <OrgInvoiceDetailRow
          label={translate({ defaultMessage: "Balance Remaining" })}
          value={formatMoneyCentsToDollarCentPrettyString(overallDetails.remainingAmount)}
        />
        {/* Payment TODO Might break this back out based on refund status */}
        {p.orgRefunds.length ? (
          <OrgInvoiceDetailRow
            label={translate({ defaultMessage: "Amount Refunded" })}
            value={formatMoneyCentsToDollarCentPrettyString(_.sum(p.orgRefunds.map(a => a.totalAmountRefundedCents)))}
            type={"paymentOrCredit"}
          />
        ) : null}
        <OrgInvoiceDetailRow
          label={
            p.childrenOrgInvoices.length
              ? translate({ defaultMessage: "Last Payment Date" })
              : translate({ defaultMessage: "Due Date" })
          }
          value={dateFormatters.mmm_d_yyyy(p.childrenOrgInvoices.slice().pop()?.dueDateMS ?? p.parentOrgInvoice.dueDateMS)}
          isBottomRow
        />
        {p.parentOrgInvoice.type === OrgInvoiceTypes.manual &&
        !paymentsTableItems.some(a => a.orgInvoice.thisInvoicePaidInFullDateMS) ? (
          <div className="bg-gray-100 rounded-md overflow-hidden flex items-center w-full mt-5">
            <div className="py-3 px-4">
              <Paperclip className="text-gray-400 h-4 w-4" />
            </div>
            <div id="invoice-link-url" className="text-gray-400 text-sm whitespace-nowrap text-ellipsis flex-1 overflow-hidden">
              <span>{`${window.location.origin}/org/${p.org.id}/payInvoice/${p.parentOrgInvoice.id}`}</span>
            </div>
            <button
              className="bg-blue-500 py-3 px-4 ml-4 rounded-md text-white h-full"
              onClick={e => {
                navigator.clipboard.writeText(document.getElementById("invoice-link-url")!.textContent!);
                (e.nativeEvent.target as Element).textContent = translate({ defaultMessage: "Copied!" });
              }}
            >
              {translate({ defaultMessage: "Copy" })}
            </button>
          </div>
        ) : null}
      </ShadowView>

      <TableSectionWrapper title={translate({ defaultMessage: "Payment Summary" })}>
        <CoolerTable
          items={paymentsTableItems}
          noItemsMessage={translate({ defaultMessage: "No payments made or scheduled..." })}
          columnDefs={[
            {
              label: translate.common.Scheduled,
              getValue(item) {
                return item.orgInvoice.thisInvoicePaidInFullDateMS
                  ? ""
                  : item.orgInvoice.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
                    item.orgInvoice.type === OrgInvoiceTypes.registrationPaymentPlanInstallment
                  ? dateFormatters.mm_dd_yyyy(moment(item.orgInvoice.autoChargeDateMS).toDate(), getCurrentLocale())
                  : "";
              },
              sortItems: (items, dir) =>
                _.orderBy(items, a => ("autoChargeDateMS" in a.orgInvoice ? a.orgInvoice.autoChargeDateMS : 0), dir),
              editable: {
                isEditable(item) {
                  return !item.orgInvoice.thisInvoicePaidInFullDateMS;
                },
                async onComplete(updatedItem) {
                  await wrapPromiseWithLoader(
                    new Promise<null>(async (resolve, reject) => {
                      if (
                        updatedItem.orgInvoice.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
                        updatedItem.orgInvoice.type === OrgInvoiceTypes.registrationPaymentPlanInstallment
                      ) {
                        if (updatedItem.orgInvoice.dueDateMS < updatedItem.orgInvoice.autoChargeDateMS) {
                          openWarningToast(
                            translate({ defaultMessage: "Updating due date to be one day after new scheduled date..." })
                          );
                        }

                        await getBifrost().orgInvoice__client__updateIndividualOrgInvoice.fetchClient({
                          orgInvoiceId: updatedItem.orgInvoice.id,
                          updatedInvoice: {
                            autoChargeDateMS: updatedItem.orgInvoice.autoChargeDateMS,
                            failedPaymentScheduledRetryMS:
                              updatedItem.orgInvoice.failedPaymentScheduledRetryMS &&
                              updatedItem.orgInvoice.failedPaymentScheduledRetryMS < updatedItem.orgInvoice.autoChargeDateMS
                                ? api.common__magicDeleteValue()
                                : updatedItem.orgInvoice.failedPaymentScheduledRetryMS,
                            dueDateMS: Math.max(
                              updatedItem.orgInvoice.dueDateMS,
                              moment(updatedItem.orgInvoice.autoChargeDateMS).add(1, "day").valueOf()
                            )
                          }
                        });
                        await p.onRefetch();
                      }
                      resolve(null);
                    })
                  );
                },
                onError(e) {
                  openErrorToast(
                    translate({
                      defaultMessage:
                        "There was a problem updating the payment. Please try again or contact support@olliesports.com"
                    })
                  );
                },
                render: a => {
                  return (
                    <PrettyCoolDateInput
                      onChange={newVal => {
                        if (newVal) {
                          if (
                            a.item.orgInvoice.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
                            a.item.orgInvoice.type === OrgInvoiceTypes.registrationPaymentPlanInstallment
                          ) {
                            a.setItem({
                              orgInvoice: {
                                ...a.item.orgInvoice,
                                autoChargeDateMS: moment(newVal).startOf("day").add(10, "hours").valueOf()
                              }
                            });
                            a.setTempState(newVal || undefined);
                          }
                        }
                      }}
                      minDate={moment().add(1, "day").startOf("day").toDate()}
                      value={a.tempState}
                    />
                  );
                },
                getInitialTempStateOnEditStart(currItem) {
                  if (
                    currItem.orgInvoice.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
                    currItem.orgInvoice.type === OrgInvoiceTypes.registrationPaymentPlanInstallment
                  ) {
                    return moment(currItem.orgInvoice.autoChargeDateMS).toDate();
                  }
                  return undefined;
                }
              }
            },
            {
              label: translate.common.Due,
              sortItems: (items, dir) => _.orderBy(items, a => a.orgInvoice.dueDateMS, dir),
              getValue(item) {
                return dateFormatters.mm_dd_yyyy(moment(item.orgInvoice.dueDateMS).toDate(), getCurrentLocale());
              },
              editable: {
                isEditable(item) {
                  return !!getIndividualOrgInvoiceAmountDetails({
                    orgInvoice: item.orgInvoice,
                    orgPayments: item.orgPayments,
                    paymentMethodType: PaymentMethodType.card // Doesn't matter
                  }).remainingAmount;
                },
                async onComplete(updatedItem) {
                  await wrapPromiseWithLoader(
                    new Promise<null>(async (resolve, reject) => {
                      const newDueDateMS = moment(updatedItem.orgInvoice.dueDateMS).endOf("day").valueOf();
                      let newAutoChargeDateMS: undefined | number = undefined;
                      if (
                        (updatedItem.orgInvoice.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
                          updatedItem.orgInvoice.type === OrgInvoiceTypes.registrationPaymentPlanInstallment) &&
                        updatedItem.orgInvoice.dueDateMS < updatedItem.orgInvoice.autoChargeDateMS
                      ) {
                        newAutoChargeDateMS = newDueDateMS - 24 * 60 * 60 * 1000;
                        openWarningToast(
                          translate({ defaultMessage: "Updating scheduled charge date to be one day before new due date!" })
                        );
                      }

                      await getBifrost().orgInvoice__client__updateIndividualOrgInvoice.fetchClient({
                        orgInvoiceId: updatedItem.orgInvoice.id,
                        updatedInvoice: {
                          dueDateMS: newDueDateMS,
                          autoChargeDateMS: newAutoChargeDateMS
                        }
                      });
                      await p.onRefetch();
                      resolve(null);
                    })
                  );
                },
                onError(e) {
                  openErrorToast(
                    translate({
                      defaultMessage:
                        "There was a problem updating the payment. Please try again or contact support@olliesports.com"
                    })
                  );
                },
                render: a => {
                  return (
                    <PrettyCoolDateInput
                      onChange={newVal => {
                        if (newVal) {
                          a.setItem({
                            orgInvoice: {
                              ...a.item.orgInvoice,
                              dueDateMS: moment(newVal).startOf("day").valueOf()
                            }
                          });
                          a.setTempState(newVal || undefined);
                        }
                      }}
                      minDate={
                        a.item.orgInvoice.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
                        a.item.orgInvoice.type === OrgInvoiceTypes.registrationPaymentPlanInstallment
                          ? moment(a.item.orgInvoice.autoChargeDateMS).add(1, "day").toDate()
                          : moment().add(1, "day").startOf("day").toDate()
                      }
                      value={a.tempState}
                    />
                  );
                },
                getInitialTempStateOnEditStart(currItem) {
                  if (
                    currItem.orgInvoice.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
                    currItem.orgInvoice.type === OrgInvoiceTypes.registrationPaymentPlanInstallment
                  ) {
                    return moment(currItem.orgInvoice.dueDateMS).toDate();
                  }
                  return undefined;
                }
              }
            },
            {
              isVisible: paymentsTableItems.some(item => item.orgPayments.filter(a => a.status === "failed").length),
              label: translate({ defaultMessage: "Failed Payments" }),
              getValue(item) {
                return (
                  <div className="flex flex-col gap-2">
                    {_.orderBy(
                      item.orgPayments.filter(a => a.status === "failed"),
                      a => {
                        return a.type === OrgPaymentType.invoiceFailedECheckPayment
                          ? a.nmiReturnResponseInfo.created_at_ms || a.createdAtMS
                          : a.createdAtMS;
                      }
                    ).map(a => {
                      const payment = a as OrgPaymentInvoiceFailed | OrgPaymentInvoiceFailedECheck;
                      const error =
                        PRETTY_PAYMENT_ERROR_CODES[payment.failureCode || ""]?.(getCurrentLocale()) ||
                        payment.failureFallbackText ||
                        translate.common.Unknown;
                      const date =
                        payment.type === OrgPaymentType.invoiceFailedECheckPayment
                          ? payment.nmiReturnResponseInfo.created_at_ms || payment.createdAtMS
                          : payment.createdAtMS;
                      return (
                        <Tooltip
                          key={a.id}
                          title={translate(
                            { defaultMessage: "Failed payment on {date} with error code: {error}" },
                            { error, date: dateFormatters.mmm_d_t_tt_a(date) }
                          )}
                        >
                          <div style={{ cursor: "help", display: "inline-flex", alignItems: "center" }}>
                            <span className="pr-1">{dateFormatters.mm_dd_yyyy(date)}</span>
                            <InformationCircleIcon className={"h-4 w-4"} />
                          </div>
                        </Tooltip>
                      );
                    })}
                  </div>
                );
              }
            },
            {
              label: translate.common.Paid,
              sortItems: (items, dir) => _.orderBy(items, a => a.orgInvoice.thisInvoicePaidInFullDateMS || 0, dir),
              getValue(item) {
                return item.orgInvoice.thisInvoicePaidInFullDateMS
                  ? dateFormatters.mm_dd_yyyy(moment(item.orgInvoice.thisInvoicePaidInFullDateMS).toDate(), getCurrentLocale())
                  : "";
              }
            },
            {
              label: translate.common.Status,
              getCellCustomClassName: item => (item.status === "failed" || item.status === "pastDue" ? "text-red-500" : ""),
              getValue(item) {
                switch (item.status) {
                  case "paid":
                    return translate.common.Paid;
                  case "partial":
                    return translate.common.Partial;
                  case "failed":
                    return translate.common.Failed;
                  case "pastDue":
                    return translate.common.PastDue;
                  case "scheduled":
                    return translate.common.Scheduled;
                  case "unpaid":
                    return translate.common.Unpaid;
                }
              }
            },
            {
              label: translate({ defaultMessage: "Base Amount Due" }),
              sortItems: (items, dir) => _.orderBy(items, a => a.totalAmount, dir),
              getValue(item) {
                return formatMoneyCentsToDollarCentPrettyString(item.totalAmount);
              },
              getValueForTotalRow(items) {
                return formatMoneyCentsToDollarCentPrettyString(_.sum(items.map(item => item.totalAmount)));
              },
              editable: {
                isEditable(item) {
                  return !!getIndividualOrgInvoiceAmountDetails({
                    orgInvoice: item.orgInvoice,
                    orgPayments: item.orgPayments,
                    paymentMethodType: PaymentMethodType.card // Doesn't matter
                  }).remainingAmount;
                },
                async onComplete(updatedItem) {
                  await wrapPromiseWithLoader(async () => {
                    if (updatedItem.creditAmount && updatedItem.creditAmount > updatedItem.totalAmount) {
                      openErrorToast(
                        translate({
                          defaultMessage: "The total amount can't be less than the amount of credit applied."
                        })
                      );
                      return;
                    }
                    await getBifrost().orgInvoice__server__updateAmountInvoiced.fetchServer({
                      orgInvoiceId: updatedItem.orgInvoice.id,
                      newAmountCents: updatedItem.totalAmount
                    });
                    await p.onRefetch();
                  });
                },
                onError(e) {
                  openErrorToast(
                    translate({
                      defaultMessage:
                        "There was a problem updating the payment. Please try again or contact support@olliesports.com"
                    })
                  );
                },
                render: a => {
                  return (
                    <PrettyCoolTextInput
                      isMoney
                      onChange={newAmtRaw => {
                        const newAmt = newAmtRaw?.match(/\d+\.?\d?\d?/)?.[0];
                        a.setTempState(newAmt || "");
                        a.setItem({ totalAmount: newAmt ? Number(newAmt) * 100 : 0 });
                      }}
                      inputProps={{ type: "number" }}
                      value={a.tempState}
                    />
                  );
                },
                getInitialTempStateOnEditStart(currItem) {
                  if (
                    currItem.orgInvoice.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
                    currItem.orgInvoice.type === OrgInvoiceTypes.registrationPaymentPlanInstallment
                  ) {
                    return moment(currItem.orgInvoice.autoChargeDateMS).toDate();
                  }
                  return undefined;
                }
              }
            },
            {
              isVisible: paymentsTableItems.some(a => a.creditAmount),
              label: translate({ defaultMessage: "Credit Amount" }),
              sortItems: (items, dir) => _.orderBy(items, a => a.creditAmount || 0, dir),
              getValue(item) {
                const credits = p.orgPayments.filter(
                  op => op.type === OrgPaymentType.invoiceCredit && op.invoiceId === item.orgInvoice.id
                ) as OrgPaymentInvoiceCredit[];
                return (
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <StyledText>{formatMoneyCentsToDollarCentPrettyString(item.creditAmount)}</StyledText>
                    {credits.length && item.creditAmount ? (
                      <TouchableOpacity
                        onPress={async () => {
                          const modal = await getFullScreenModal({
                            title: translate.common.Credits,
                            containerStyle: { maxWidth: 500 },
                            children: (
                              <CreditsTable
                                closeParentModal={() => modal.close()}
                                credits={credits}
                                triggerRefetch={p.onRefetch}
                              />
                            )
                          });
                        }}
                        style={{ width: 20, height: 20, marginLeft: 4 }}
                      >
                        <EyeIcon color={COLORS.blue_66} />
                      </TouchableOpacity>
                    ) : null}
                  </View>
                );
              },
              getValueForTotalRow(items) {
                return formatMoneyCentsToDollarCentPrettyString(_.sum(items.map(item => item.creditAmount)));
              }
            },
            {
              label: translate({ defaultMessage: "Amount Paid" }),
              getValue(item) {
                return formatMoneyCentsToDollarCentPrettyString(item.paidAmount);
              },
              sortItems: (items, dir) => _.orderBy(items, a => a.paidAmount, dir),
              getValueForTotalRow(items) {
                return formatMoneyCentsToDollarCentPrettyString(_.sum(items.map(item => item.paidAmount)));
              }
            },
            {
              label: translate({ defaultMessage: "Amount Paid + Fees" }),
              sortItems: (items, dir) =>
                _.orderBy(items, item => item.lateFeesPaidAmount + item.paidAmount + item.otherFeesPaidAmount, dir),
              getValue(item) {
                return formatMoneyCentsToDollarCentPrettyString(
                  item.lateFeesPaidAmount + item.paidAmount + item.otherFeesPaidAmount
                );
              },
              getValueForTotalRow(items) {
                return formatMoneyCentsToDollarCentPrettyString(
                  _.sum(items.map(item => item.lateFeesPaidAmount + item.paidAmount + item.otherFeesPaidAmount))
                );
              }
            },
            // Payment TODO: Might need to break this out based on refund status */
            p.orgRefunds.length
              ? {
                  label: translate({ defaultMessage: "Amount Refunded" }),
                  sortItems: (items, dir) =>
                    _.orderBy(items, item => _.sum(item.orgRefunds.map(a => a.totalAmountRefundedCents)), dir),
                  getValue(item) {
                    return formatMoneyCentsToDollarCentPrettyString(_.sum(item.orgRefunds.map(a => a.totalAmountRefundedCents)));
                  },
                  getValueForTotalRow(items) {
                    return formatMoneyCentsToDollarCentPrettyString(
                      _.sum(items.map(item => _.sum(item.orgRefunds.map(a => a.totalAmountRefundedCents))))
                    );
                  }
                }
              : null,
            {
              label: translate({ defaultMessage: "Balance" }),
              sortItems: (items, dir) => _.orderBy(items, item => item.remainingAmount, dir),
              getValue(item) {
                return formatMoneyCentsToDollarCentPrettyString(item.remainingAmount);
              },
              getValueForTotalRow(items) {
                return formatMoneyCentsToDollarCentPrettyString(_.sum(items.map(item => item.remainingAmount)));
              }
            },
            {
              label: translate({ defaultMessage: "Payor" }),
              getValue(item) {
                let payor = item.orgPayments
                  .map(op => {
                    if (op.type === OrgPaymentType.invoiceDefault) {
                      if (!op.paymentMethodSnapshot?.ownerName) {
                        return translate.common.Unknown;
                      }

                      return getLabelFromPaymentMethodSnapshot(op.paymentMethodSnapshot);
                    } else {
                      return null;
                    }
                  })
                  .filter(a => a)[0];

                if (!payor && item.orgInvoice.accountIdScheduledToPay) {
                  const method = allPossiblePlayerPaymentMethods?.find(
                    a => a.accountId === item.orgInvoice.accountIdScheduledToPay && a.isDefault
                  );

                  if (method) {
                    payor = getLabelFromPaymentMethodSnapshot(method);
                  }
                }

                return payor || "";
              },
              editable: {
                isEditable: item =>
                  !item.orgPayments.find(op => op.type === OrgPaymentType.invoiceDefault && op.accountId) &&
                  ((allPossiblePlayerPaymentMethods || []).filter(a => a.isDefault).length > 1 ||
                    !item.orgInvoice.accountIdScheduledToPay),
                shouldCompleteOnItemChange: true,
                async onComplete(updatedItem) {
                  await wrapPromiseWithLoader(async () => {
                    await getBifrost().orgInvoice__client__updateIndividualOrgInvoice.fetchClient({
                      orgInvoiceId: updatedItem.orgInvoice.id,
                      updatedInvoice: {
                        accountIdScheduledToPay: updatedItem.orgInvoice.accountIdScheduledToPay
                      }
                    });
                    await p.onRefetch();
                  });
                },
                onError(e) {
                  openErrorToast(
                    translate({
                      defaultMessage:
                        "There was a problem updating the payment. Please try again or contact support@olliesports.com"
                    })
                  );
                },
                render: a => {
                  const relevantPaymentMethods = (allPossiblePlayerPaymentMethods || []).filter(a => a.isDefault);
                  const selectedPaymentMethod = relevantPaymentMethods.find(
                    b => b.accountId === a.item.orgInvoice.accountIdScheduledToPay && b.isDefault
                  )?.id;

                  return (
                    <PrettyCoolSelectInput
                      value={selectedPaymentMethod}
                      onChange={newPaymentId => {
                        a.setItem(s => {
                          s.orgInvoice.accountIdScheduledToPay = allPossiblePlayerPaymentMethods!.find(
                            b => b.id === newPaymentId
                          )!.accountId;
                        });
                      }}
                      options={relevantPaymentMethods.map(a => ({
                        label: getLabelFromPaymentMethodSnapshot(a),
                        value: a.id
                      }))}
                      allowClear
                    />
                  );
                }
              }
            }
          ]}
          defaultSortSettings={{
            label: translate.common.Due,
            dir: "asc"
          }}
          rowButtons={
            compute.org.hasOrgPermission({
              accountId: getCurrentUserAccountId(),
              org: p.org,
              permission: ORG_PERMISSIONS.manageFinances
            })
              ? [
                  {
                    isVisible: item => {
                      const defaultPmt = item.orgPayments.find(
                        pmt => pmt.type === OrgPaymentType.invoiceDefault
                      ) as OrgPaymentInvoiceDefault;
                      return (
                        !!defaultPmt &&
                        getRefundAvailabilityDetails({ payment: defaultPmt, refunds: item.orgRefunds }).canIssueRefund
                      );
                    },
                    getLabel: () => translate({ defaultMessage: "Issue Refund" }),
                    type: "dropdown",
                    getIcon: () => <ReceiptRefundIcon color={COLORS.blue} />,
                    onClick: async item => {
                      const defaultPmt = item.orgPayments.find(
                        pmt => pmt.type === OrgPaymentType.invoiceDefault
                      ) as OrgPaymentInvoiceDefault;

                      openPaymentRefundModal({
                        payment: defaultPmt,
                        previousRefunds: item.orgRefunds,
                        onComplete: async () => {
                          await p.onRefetch();
                        }
                      });
                    }
                  },
                  {
                    isVisible: item => {
                      const details = getIndividualOrgInvoiceAmountDetails({
                        orgInvoice: item.orgInvoice,
                        orgPayments: item.orgPayments,
                        paymentMethodType: PaymentMethodType.card // Doesn't matter
                      });

                      return details.remainingAmount > 0;
                    },
                    getLabel: () => translate({ defaultMessage: "Forgive" }),
                    type: "dropdown",
                    getIcon: () => {
                      return (
                        <svg style={{ fill: COLORS.blue }} viewBox="0 0 24 24">
                          <path d="M16 13c3.09-2.81 6-5.44 6-7.7C22 3.45 20.55 2 18.7 2c-1.04 0-2.05.49-2.7 1.25C15.34 2.49 14.34 2 13.3 2 11.45 2 10 3.45 10 5.3c0 2.26 2.91 4.89 6 7.7zm-2.7-9c.44 0 .89.21 1.18.55L16 6.34l1.52-1.79c.29-.34.74-.55 1.18-.55.74 0 1.3.56 1.3 1.3 0 1.12-2.04 3.17-4 4.99-1.96-1.82-4-3.88-4-4.99 0-.74.56-1.3 1.3-1.3zM19 16h-2c0-1.2-.75-2.28-1.87-2.7L8.97 11H1v11h6v-1.44l7 1.94 8-2.5v-1c0-1.66-1.34-3-3-3zM3 20v-7h2v7H3zm10.97.41L7 18.48V13h1.61l5.82 2.17c.34.13.57.46.57.83 0 0-1.99-.05-2.3-.15l-2.38-.79-.63 1.9 2.38.79c.51.17 1.04.26 1.58.26H19c.39 0 .74.23.9.56l-5.93 1.84z"></path>
                        </svg>
                      );
                    },
                    async onClick(item) {
                      const details = getIndividualOrgInvoiceAmountDetails({
                        orgInvoice: item.orgInvoice,
                        orgPayments: item.orgPayments,
                        paymentMethodType: PaymentMethodType.card // Doesn't matter
                      });

                      await openOrgPaymentInvoiceCreditAddToScheduledPaymentModal({
                        maxAmountCents: details.remainingAmount,
                        initialAmountCents: details.remainingAmount,
                        async onComplete() {
                          await p.onRefetch();
                        },
                        orgInvoice: item.orgInvoice,
                        orgInvoiceParent: p.parentOrgInvoice
                      });
                    },
                    getTheme(item) {
                      return "red";
                    }
                  },
                  {
                    isVisible: item => {
                      const details = getIndividualOrgInvoiceAmountDetails({
                        orgInvoice: item.orgInvoice,
                        orgPayments: item.orgPayments,
                        paymentMethodType: PaymentMethodType.card // Doesn't matter
                      });

                      return details.remainingAmount > 0;
                    },
                    getLabel: () => translate({ defaultMessage: "Pay Now" }),
                    type: "dropdown",
                    getIcon: () => {
                      return <CurrencyDollarIcon />;
                    },
                    async onClick(item) {
                      const details = getIndividualOrgInvoiceAmountDetails({
                        orgInvoice: item.orgInvoice,
                        orgPayments: item.orgPayments,
                        paymentMethodType: PaymentMethodType.card // Doesn't matter
                      });

                      if (details.remainingAmount > 0) {
                        const details = getIndividualOrgInvoiceAmountDetails({
                          orgInvoice: item.orgInvoice,
                          orgPayments: item.orgPayments,
                          paymentMethodType: PaymentMethodType.card // Doesn't matter
                        });

                        openOrgPaymentInvoiceChargeNowModal({
                          onComplete: async () => {
                            await p.onRefetch();
                          },
                          orgInvoice: item.orgInvoice,
                          remainingAmountCents: details.remainingAmount,
                          playerBundle: p.playerBundle,
                          orgPayments: item.orgPayments,
                          parentOrgInvoice: p.parentOrgInvoice,
                          orgPaymentInvoiceCredits: p.orgPayments.filter(
                            a => a.type === OrgPaymentType.invoiceCredit
                          ) as OrgPaymentInvoiceCredit[],
                          feeDetails: orgSettings?.customFeeDetails,
                          futureInvoices: p.childrenOrgInvoices.filter(oi => !oi.thisInvoicePaidInFullDateMS)
                        });
                      }
                    },
                    getTheme(item) {
                      return "red";
                    }
                  }
                ]
              : []
          }
          getItemKey={item => {
            return item.orgInvoice.id;
          }}
        />
      </TableSectionWrapper>
      {creditGroupings.length ? (
        <div style={{ marginBottom: 8, marginTop: 28 }}>
          <StyledText style={{ fontSize: 24, fontWeight: "bold", flex: 1 }}>{translate.common.Credits}</StyledText>
          <CreditGroupingTable groupings={creditGroupings} onDelete={p.onRefetch} org={p.org} />
        </div>
      ) : null}
      {p.orgRefunds.length ? (
        <div style={{ paddingTop: 32, paddingBottom: 8 }}>
          <StyledText style={{ fontSize: 24, fontWeight: "bold", flex: 1 }}>{translate.common.Refunds}</StyledText>
          <CoolerTable
            items={_.orderBy(p.orgRefunds, a => a.createdAtMS, "desc")}
            getItemKey={a => a.id}
            columnDefs={[
              {
                label: translate.common.Date,
                getValue(item) {
                  return dateFormatters.mm_dd_yyyy(moment(item.createdAtMS).toDate(), getCurrentLocale());
                }
              },
              {
                label: translate.common.Amount,
                getValue(item) {
                  return formatMoneyCentsToDollarCentPrettyString(item.totalAmountRefundedCents);
                }
              },
              {
                label: translate.common.Note,
                getValue(item) {
                  return item.note;
                }
              },
              {
                label: translate({ defaultMessage: "Refunded By", description: "As in the account who triggered the refund." }),
                getValue(item) {
                  const account = refundAccounts?.find(acc => acc.id === item.refundedByAccountId);
                  if (account) {
                    return `${account.firstName} ${account.lastName}`;
                  }
                  return "";
                }
              }
            ]}
          />
        </div>
      ) : null}
      {registrationAnswersExcludingAdditionalSteps?.length ? (
        <RegistrationAnswersTable registrationAnswers={registrationAnswersExcludingAdditionalSteps} />
      ) : null}
      {p.orgRegistration && additionalSteps?.length ? (
        <TableSectionWrapper title={translate({ defaultMessage: "Additional Steps" })}>
          <CoolerTable
            items={_.orderBy(additionalSteps, a => a.shortTitle, "asc")}
            columnDefs={[
              {
                getValue(item) {
                  return item.shortTitle;
                },
                label: translate.common.Title
              },
              {
                getValue(item) {
                  return item.descriptionMD;
                },
                label: translate.common.Description
              },
              {
                label: translate({ defaultMessage: "Completed" }),
                getValue(item) {
                  const answer = registrationAnswers?.find(a => a.questionId === item.id);
                  if (answer && answer.type === OrgRegistrationQuestionType.additionalStep) {
                    return (
                      <div className="flex flex-row ">
                        {dateFormatters.mm_dd_yyyy(moment(answer.completedAtMS).toDate(), getCurrentLocale())}
                        <div
                          className="h-4 w-4 mt-0.5 ml-2 cursor-pointer"
                          onClick={async () => {
                            const confirm = await getConfirm({
                              title: translate.common.Undo,
                              confirmButtonColor: "blue",
                              subtitle: translate({
                                defaultMessage: "Do you want to undo the completion of this additional step?"
                              })
                            });
                            if (confirm) {
                              await wrapPromiseWithLoader(
                                new Promise<void>(async (resolve, reject) => {
                                  try {
                                    await getBifrost().orgRegistrationAnswer__client__undoAdditionalStepAnswer.fetchClient({
                                      answerId: answer.id,
                                      orgRegistrationId: p.orgRegistration!.id
                                    });
                                    await refetchAnswers();
                                    openSuccessToast();
                                  } catch (e) {
                                    openErrorToast(translate.common.SomethingWentWrong);
                                  }
                                  resolve();
                                })
                              );
                            }
                          }}
                        >
                          <TrashIcon color={COLORS.red_66} />
                        </div>
                      </div>
                    );
                  }
                  return (
                    <StyledButton
                      className="w-32"
                      onClick={async () => {
                        const confirm = await getConfirm({
                          title: translate({ defaultMessage: "Complete", description: "Verb, as in to complete a task" }),
                          subtitle: translate({ defaultMessage: "Do you want to mark this additional step as completed?" }),
                          confirmButtonColor: "blue"
                        });
                        if (confirm) {
                          await getBifrost().orgRegistrationAnswer__client__completeAdditionalStepAnswer.fetchClient({
                            answer: {
                              type: OrgRegistrationQuestionType.additionalStep,
                              completedAtMS: Date.now(),
                              orgId: p.org.id,
                              questionId: item.id,
                              questionSnapshot: item
                            },
                            orgRegistrationId: p.orgRegistration!.id
                          });
                          await refetchAnswers();
                        }
                      }}
                      text={translate({ defaultMessage: "Complete", description: "Verb, as in to complete a task" })}
                    />
                  );
                }
              }
            ]}
            getItemKey={a => a.id}
          />
        </TableSectionWrapper>
      ) : null}
    </View>
  );
}

function InvoiceAccountName(p: { accountId: AccountId }) {
  const { accounts } = useAccounts({ accountIds: [p.accountId] });
  if (!accounts.length) {
    return null;
  }

  return <div>{`${accounts[0].firstName} ${accounts[0].lastName}`}</div>;
}

function RegistrationAnswersTable(p: { registrationAnswers: OrgRegistrationAnswer[] }) {
  return <OrgRegistrationAnswersTable orgRegistrationAnswers={p.registrationAnswers} />;
}

function CreditGroupingTable(p: {
  groupings: { credit: OrgPaymentInvoiceCredit; invoice: OrgInvoice; orgPayments: OrgPaymentInvoice[] }[][];
  onDelete: () => Promise<void>;
  org: Org;
}) {
  return (
    <CoolerTable
      items={p.groupings.filter(g => g.length)}
      getItemKey={item => {
        return item.map(a => a.credit.id).join("");
      }}
      noItemsMessage={translate({ defaultMessage: "No credits applied to invoice..." })}
      columnDefs={[
        {
          label: translate.common.Date,
          getValue(item) {
            return dateFormatters.mm_dd_yyyy(moment(item[0].credit.createdAtMS).toDate(), getCurrentLocale());
          }
        },
        {
          label: translate.common.Amount,
          getValue(item) {
            return formatMoneyCentsToDollarCentPrettyString(_.sum(item.map(a => a.credit.amountCents)));
          }
        },
        {
          label: translate({ defaultMessage: "Applied By", description: `As in a credit was "Applied By" John` }),
          getValue(item) {
            return <AccountName accountId={item[0].credit.appliedByAccountId} />;
          }
        },
        {
          label: translate.common.Note,
          getValue(item) {
            return item[0].credit.note;
          }
        },
        {
          label: translate({ defaultMessage: "# Payments Applied To" }),
          getValue(item) {
            return item.length;
          }
        }
      ]}
      rowButtons={
        compute.org.hasOrgPermission({
          accountId: getCurrentUserAccountId(),
          org: p.org,
          permission: ORG_PERMISSIONS.manageFinances
        })
          ? [
              {
                getLabel: () => translate.common.Delete,
                type: "inline",
                getIcon: () => {
                  return <TrashIcon color={COLORS.red_66} />;
                },
                async onClick(item) {
                  const invoicesWhereCreditIsEligibleForDeletion = item.filter(a =>
                    isIndividualOrgInvoiceEligibleForCreditDeletion({
                      orgInvoice: a.invoice,
                      orgPayments: a.orgPayments
                    })
                  );
                  const invoicesWhereCreditIsNotEligibleForDeletion = item.filter(
                    a =>
                      !isIndividualOrgInvoiceEligibleForCreditDeletion({
                        orgInvoice: a.invoice,
                        orgPayments: a.orgPayments
                      })
                  );

                  if (!invoicesWhereCreditIsEligibleForDeletion.length) {
                    return await getAlert({
                      title: translate({ defaultMessage: "Cannot Delete Credit" }),
                      subtitle: `${translate({
                        defaultMessage:
                          "You cannot delete credits if doing so is likely to confuse or frustrate players/parents. For example, a credit cannot be deleted if the user has completed the payment that the credit was applied to or if the credit covered the whole payment and it's past the due date."
                      })}\n\n${translate({
                        defaultMessage:
                          "Please create a new invoice if you need to collect additional money from this player or parent."
                      })}`
                    });
                  }
                  const confirm = await getConfirm({
                    confirmButtonColor: "red",
                    confirmText: translate.common.Delete,
                    title: translate({ defaultMessage: "Delete Credit?" }),
                    subtitle: invoicesWhereCreditIsNotEligibleForDeletion.length
                      ? `${translate(
                          {
                            defaultMessage:
                              "NOTE: Only {amount} of this credit is eligible to deleted. A portion of the credit is not eligible to be deleted because the payment it was applied to has been paid or the credit covered the entire payment and it's past due."
                          },
                          {
                            amount: formatMoneyCentsToDollarCentPrettyString(
                              _.sum(invoicesWhereCreditIsEligibleForDeletion.map(a => a.credit.amountCents))
                            )
                          }
                        )}\n\n${translate({ defaultMessage: "Are you sure you want to delete this credit?" })}`
                      : translate({ defaultMessage: "Are you sure you want to delete this credit?" })
                  });

                  if (confirm) {
                    try {
                      const promise = new Promise(async (resolve, reject) => {
                        await getBifrost().orgPayment__client__deleteOrgPaymentInvoiceCredits.fetchClient({
                          orgPaymentInvoiceCreditIds: invoicesWhereCreditIsEligibleForDeletion.map(a => a.credit.id)
                        });
                        await p.onDelete();
                        resolve(undefined);
                      });
                      await wrapPromiseWithLoader(promise);
                      openSuccessToast();
                    } catch (e) {
                      openErrorToast(
                        translate({
                          defaultMessage:
                            "There was a problem deleting the credit. Please try again or contact support@olliesports.com"
                        })
                      );
                    }
                  }
                },
                getTheme(item) {
                  return "red";
                }
              }
            ]
          : []
      }
    />
  );
}

function CreditsTable(p: {
  credits: OrgPaymentInvoiceCredit[];
  triggerRefetch: () => Promise<void>;
  closeParentModal: () => void;
}) {
  return (
    <CoolerTable
      items={p.credits}
      columnDefs={[
        {
          label: translate.common.Date,
          getValue(item) {
            return dateFormatters.mm_dd_yyyy(moment(item.createdAtMS).toDate(), getCurrentLocale());
          }
        },
        {
          label: translate.common.Amount,
          getValue(item) {
            return formatMoneyCentsToDollarCentPrettyString(item.amountCents);
          },
          getValueForTotalRow(items) {
            return formatMoneyCentsToDollarCentPrettyString(_.sum(p.credits.map(c => c.amountCents)));
          }
        },
        {
          label: translate({ defaultMessage: "Applied By", description: `As in a credit was "Applied By" John` }),
          getValue(item) {
            return <AccountName accountId={item.appliedByAccountId} />;
          }
        },
        {
          label: translate.common.Note,
          getValue(item) {
            return item.note;
          }
        }
      ]}
      rowButtons={[
        {
          type: "inline",
          getLabel: () => "",
          onClick: async item => {
            const yes = await getConfirm({
              cancelText: translate.common.Cancel,
              confirmText: translate.common.Confirm,
              confirmButtonColor: "red",
              title: translate({ defaultMessage: "Delete Credit?" }),
              subtitle: translate(
                { defaultMessage: "Are you sure you wish to delete this credit for {amount}?" },
                { amount: formatMoneyCentsToDollarCentPrettyString(item.amountCents) }
              )
            });

            if (yes) {
              try {
                await wrapPromiseWithLoader(
                  async () => {
                    await getBifrost().orgPayment__client__deleteOrgPaymentInvoiceCredits.fetchClient({
                      orgPaymentInvoiceCreditIds: [item.id]
                    });

                    await new Promise(res => setTimeout(res, 1000));

                    await p.triggerRefetch();
                  },
                  { minTimeMS: 0 }
                );

                p.closeParentModal();
              } catch (e) {
                console.error(e);
                window.alert(
                  translate({ defaultMessage: "Problem removing credit. Please try again or contact support@olliesports.com" })
                );
              }
            }
          },
          getIcon: () => <TrashIcon color={COLORS.red_66} />
        }
      ]}
      getItemKey={item => item.id}
    />
  );
}

function AccountName(p: { accountId: AccountId }) {
  const { data: account } = getBifrost().account__client__getAccount.useClient({
    accountId: p.accountId
  });
  return <StyledText>{account ? `${account.firstName} ${account.lastName}` : ""}</StyledText>;
}

function OrgInvoiceDetailRow(p: {
  label?: string;
  value: ReactNode;
  isBottomRow?: boolean;
  type?: "paymentOrCredit";
  infoToolTipText?: string;
}) {
  return (
    <div
      style={{
        display: "flex",
        marginBottom: p.isBottomRow ? 0 : 8
      }}
    >
      {p.label ? <StyledText style={{ fontWeight: "bold", color: COLORS.black, flex: 1 }}>{`${p.label}:`}</StyledText> : null}
      <StyledText style={{ color: COLORS.black, fontWeight: p.label ? "normal" : "bold" }}>
        {`${p.type === "paymentOrCredit" && typeof p.value === "string" && Number(p.value.replace(/[^\d]/g, "")) ? "-" : ""}`}
        {p.value}
      </StyledText>
      {p.infoToolTipText ? <InfoTooltipIcon title={p.infoToolTipText} /> : null}
    </div>
  );
}

export function getLabelFromPaymentMethodSnapshot(
  snap:
    | { type: PaymentMethodType.bank; ownerName: string; accountNumberLast4: string }
    | { type: PaymentMethodType.card; ownerName: string; cardNumberLast4: string }
) {
  return `${snap.ownerName} (${translate(
    {
      defaultMessage: "{paymentType} ending in {num}"
    },
    {
      paymentType: snap.type === PaymentMethodType.bank ? translate.common.BankAccount : translate.common.CreditDebitCardShort,
      num: snap.type === PaymentMethodType.bank ? snap.accountNumberLast4 || "" : snap.cardNumberLast4 || ""
    }
  )})`;
}
