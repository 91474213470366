import { Typography, useTheme, useMediaQuery, Box, Button, SvgIcon } from "@material-ui/core";
import { View } from "react-native-web";
import { ORG_PERMISSIONS, Org, OrgInvoiceTypes, OrgSeason } from "@ollie-sports/models";
import { CenteredLoader } from "../../components/CenteredLoader";
import { dateFormatters, getCurrentLocale, translate } from "@ollie-sports/i18n";
import { useParams } from "react-router-dom";
import { getBifrost } from "../../services/bifrost.service";
import { COLORS, compute } from "@ollie-sports/core";
import CoolerTable, { CoolerTableMethods } from "../../components/CoolerTable";
import _ from "lodash";
import {
  PencilIcon,
  DocumentDuplicateIcon,
  PlusCircleIcon,
  ArchiveBoxXMarkIcon,
  ArrowUturnLeftIcon
} from "@heroicons/react/24/outline";
import { useOrg } from "../../hooks/useOrg";
import { openOrgSeasonsAddEditModal } from "./OrgSeasonAddEdit";
import { CoolSelectInput } from "../../components/Inputs/CoolSelectInput";
import { useRef, useState } from "react";
import getConfirm from "../../components/modals/getConfirm";
import { CoolTextInput } from "../../components/Inputs/CoolTextInput";
import { CoolCheckboxInput } from "../../components/Inputs/CoolCheckboxInput";
import { openErrorToast } from "../../utils/openErrorToast";
import { PrettyCoolDateInput, PrettyCoolTextInput } from "../../components/Form";
import moment from "moment";
import { getCurrentUserAccountId } from "../../hooks/commonDataUtils";
import { wrapPromiseWithLoader } from "../../utils/wrapPromiseWithLoader";
import { MoreHorizontal } from "react-feather";
import { ActionButtonDropdown } from "../../components/ActionButtonDropdown";
import { openOrgInvoiceAddEditModal } from "./OrgInvoiceAddEdit";

export default function OrgSeasons() {
  const params: any = useParams();
  const orgId = params.orgId;

  const { org, isLoading } = useOrg({ orgId });

  const { data: orgSeasons, isLoading: isLoadingCoupons } =
    getBifrost().orgSeason__client__getOrgSeasonsForOrgSubscription.useClientSubscription(
      {
        orgId
      },
      { notifyOnMetaDataChanges: true }
    );

  return (
    <Box px={3} py={2} display="flex" style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        {isLoading || isLoadingCoupons ? (
          <CenteredLoader />
        ) : org ? (
          <OrgSeasonsInner org={org} orgSeasons={orgSeasons ?? []} />
        ) : (
          <Typography>{translate({ defaultMessage: "Failed to load org" })}</Typography>
        )}
      </View>
    </Box>
  );
}

function OrgSeasonsInner(p: { org: Org; orgSeasons: OrgSeason[] }) {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumDeviceOrSmaller = useMediaQuery(theme.breakpoints.down("md"));
  const [searchInput, setSearchInput] = useState("");
  const [showArchived, setShowArchived] = useState(false);
  const { data: orgSecret } = getBifrost().orgSecret__client__getOrgSecretSubscription.useClientSubscription({ orgId: p.org.id });

  const hasOrgManageFinancesPermission = compute.org.hasOrgPermission({
    org: p.org,
    accountId: getCurrentUserAccountId(),
    permission: ORG_PERMISSIONS.manageFinances
  });

  const coolTableRef = useRef<CoolerTableMethods>(null);

  return (
    <View style={{ flex: 1 }}>
      <View style={{ flexDirection: "row" }}>
        <h1 className="text-2xl sm:text-4xl flex-1 mt-4">{translate.common.Seasons}</h1>
        {compute.org.hasOrgPermission({
          accountId: getCurrentUserAccountId(),
          org: p.org,
          permission: ORG_PERMISSIONS.manageFinances
        }) ? (
          <ActionButtonDropdown
            color="secondary"
            variant="contained"
            actions={[
              {
                key: "new-season",
                label: () => translate({ defaultMessage: "New Season" }),
                onClick: async () => {
                  openOrgSeasonsAddEditModal({
                    type: "create",
                    org: p.org
                  });
                }
              },
              {
                key: "csv-export",
                label: () => translate({ defaultMessage: "Export CSV" }),
                onClick: () => {
                  coolTableRef.current?.downloadCurrentDataToCSV("seasons.csv");
                }
              }
            ]}
          >
            <span style={{ fontWeight: "bold", marginRight: 6 }}>{translate({ defaultMessage: "Actions" })}</span>
            <SvgIcon style={{ paddingRight: 6 }}>
              <MoreHorizontal />
            </SvgIcon>
          </ActionButtonDropdown>
        ) : null}
      </View>
      {!orgSecret?.nmiConfig ? (
        <div className="mt-4 text-white bg-red-500 p-4 border rounded-lg shadow-lg">
          {translate({
            defaultMessage:
              "In order to collect registration payments, your club payment account must be set up. Please contact us at support@olliesports.com to get started!"
          })}
        </div>
      ) : null}
      <div className="pb-8">
        <CoolerTable
          methodsRef={coolTableRef}
          condensed={isMediumDeviceOrSmaller}
          style={{ marginTop: 30 }}
          items={p.orgSeasons}
          noItemsMessage={translate({ defaultMessage: "No seasons created yet..." })}
          noFilteredItemsMessage={translate({ defaultMessage: "No seasons matching selected filters..." })}
          paginationOptions={{
            defaultPageSize: 25,
            pageSizeOptions: [25, 50, 100],
            persistenceKey: "org-seasons"
          }}
          getRowCustomClassName={item => {
            if (item.archivedAtMS) {
              return "bg-red-100";
            }
            return "";
          }}
          defaultSortSettings={{ label: translate.common.Name, dir: "asc" }}
          rowButtons={
            compute.org.hasOrgPermission({
              accountId: getCurrentUserAccountId(),
              org: p.org,
              permission: ORG_PERMISSIONS.manageFinances
            })
              ? [
                  //Edit
                  {
                    getLabel: () => translate.common.Edit,
                    getIcon: () => <PencilIcon color={COLORS.blue_66} />,
                    type: "dropdown",
                    onClick: async item => {
                      openOrgSeasonsAddEditModal({
                        type: "edit",
                        org: p.org,
                        initialOrgSeason: item
                      });
                    }
                  },
                  //Clone
                  {
                    getLabel: () => translate({ defaultMessage: "Clone" }),
                    type: "dropdown",
                    getIcon: () => <DocumentDuplicateIcon color={COLORS.blue_66} />,
                    onClick: async item => {
                      openOrgSeasonsAddEditModal({
                        type: "create",
                        org: p.org,
                        initialOrgSeason: { ...item, name: `${item.name} (${translate.common.CopyNoun})` }
                      });
                    }
                  },
                  // Archive
                  {
                    getLabel: item =>
                      item.archivedAtMS
                        ? translate({ defaultMessage: "Restore Season" })
                        : translate({ defaultMessage: "Archive/Delete Season" }),
                    type: "dropdown",
                    getIcon: item => {
                      if (item.archivedAtMS) {
                        return <ArrowUturnLeftIcon color={COLORS.blue} />;
                      }
                      return <ArchiveBoxXMarkIcon color={COLORS.red} />;
                    },
                    onClick: async item => {
                      const { data: hasRelatedEntities } = await wrapPromiseWithLoader(
                        getBifrost().orgSeason__client__hasRelatedEntities.fetchClient({
                          orgSeasonId: item.id,
                          orgId: item.orgId,
                          selfAccountId: getCurrentUserAccountId()
                        })
                      );

                      const action = item.archivedAtMS ? "restore" : hasRelatedEntities ? "archive" : "delete";
                      let title: string;
                      let subtitle: string;
                      let confirmButtonColor: "red" | "blue";
                      let confirmText: string;

                      if (action === "restore") {
                        title = translate({ defaultMessage: "Restore Season?" });
                        subtitle = translate({
                          defaultMessage:
                            "Are you sure you want to restore this season to become active? Registrations associated with this season will become active again."
                        });
                        confirmButtonColor = "blue";
                        confirmText = translate({ defaultMessage: "Restore" });
                      } else if (action === "delete") {
                        title = translate({ defaultMessage: "Delete Season?" });
                        subtitle = translate({
                          defaultMessage: "Are you sure you wish to delete this season?"
                        });
                        confirmButtonColor = "red";
                        confirmText = translate({ defaultMessage: "Delete" });
                      } else if (action === "archive") {
                        title = translate({ defaultMessage: "Archive Season?" });
                        subtitle = translate({
                          defaultMessage:
                            "WARNING PLEASE READ: There are registrations and/or packages associated with this season. Are you sure you wish to archive it and hide it from parents and players? Be aware that  parents and players will no longer receive warnings for past due registrations associated with this season."
                        });
                        confirmButtonColor = "red";
                        confirmText = translate({ defaultMessage: "Archive" });
                      } else {
                        ((a: never) => {})(action);
                        throw new Error("Never");
                      }

                      const confirm = await getConfirm({
                        title,
                        subtitle,
                        confirmButtonColor,
                        confirmText
                      });

                      if (confirm) {
                        await wrapPromiseWithLoader(
                          getBifrost().orgSeason__client__toggleSeasonState.fetchClient({
                            orgSeasonId: item.id,
                            action: action
                          })
                        );
                      }
                    }
                  }
                ]
              : []
          }
          columnDefs={[
            {
              label: translate.common.Name,
              getValue(item) {
                return item.name;
              },
              sortItems(items, dir) {
                return _.orderBy(items, a => a.name, dir);
              },
              editable: hasOrgManageFinancesPermission
                ? {
                    onComplete: newItem =>
                      getBifrost().orgSeason__client__updateOrgSeason.fetchClient({
                        orgSeason: newItem
                      }),
                    onError: e => {
                      openErrorToast(translate({ defaultMessage: "Unable to save season name change!" }));
                      console.error(e);
                    },
                    render: a => (
                      <PrettyCoolTextInput
                        onChange={newVal => {
                          a.setItem({ name: newVal });
                        }}
                        inputProps={{ type: "text" }}
                        value={a.item.name}
                      />
                    )
                  }
                : undefined
            },
            {
              label: translate.common.StartDate,
              getValue(item) {
                return dateFormatters.mm_dd_yyyy(item.startDateMS, getCurrentLocale());
              },
              sortItems(items, dir) {
                return _.orderBy(items, a => a.startDateMS, dir);
              },
              editable: hasOrgManageFinancesPermission
                ? {
                    onComplete: newItem =>
                      getBifrost().orgSeason__client__updateOrgSeason.fetchClient({
                        orgSeason: newItem
                      }),
                    onError: e => {
                      openErrorToast(translate({ defaultMessage: "Unable to save changes to season start date" }));
                      console.error(e);
                    },
                    render: a => (
                      <PrettyCoolDateInput
                        value={a.item.startDateMS ? moment(a.item.startDateMS).toDate() : undefined}
                        maxDate={moment(a.item.endDateMS).subtract(1, "day").toDate()}
                        onChange={newVal => {
                          const startDate = moment(newVal);
                          if (newVal && startDate.isBefore(moment(a.item.endDateMS))) {
                            a.setItem({ startDateMS: startDate.valueOf() });
                          } else {
                            openErrorToast(translate({ defaultMessage: "Start date must be before end date!" }));
                          }
                        }}
                      />
                    )
                  }
                : undefined
            },
            {
              label: translate.common.EndDate,
              getValue(item) {
                return dateFormatters.mm_dd_yyyy(item.endDateMS, getCurrentLocale());
              },
              sortItems(items, dir) {
                return _.orderBy(items, a => a.endDateMS, dir);
              },
              editable: hasOrgManageFinancesPermission
                ? {
                    onComplete: newItem =>
                      getBifrost().orgSeason__client__updateOrgSeason.fetchClient({
                        orgSeason: newItem
                      }),
                    onError: e => {
                      openErrorToast(translate({ defaultMessage: "Unable to save changes to season end date" }));
                      console.error(e);
                    },
                    render: a => (
                      <PrettyCoolDateInput
                        minDate={moment(a.item.startDateMS).add(1, "day").toDate()}
                        value={a.item.endDateMS ? moment(a.item.endDateMS).toDate() : undefined}
                        onChange={newVal => {
                          if (newVal) {
                            a.setItem({ endDateMS: newVal.valueOf() });
                          }
                        }}
                      />
                    )
                  }
                : undefined
            },
            {
              label: translate({ defaultMessage: "Registration Due Date" }),
              getValue(item) {
                return dateFormatters.mm_dd_yyyy(item.registrationDueDateMS, getCurrentLocale());
              },
              sortItems(items, dir) {
                return _.orderBy(items, a => a.registrationDueDateMS, dir);
              },
              editable: hasOrgManageFinancesPermission
                ? {
                    onComplete: newItem =>
                      getBifrost().orgSeason__client__updateOrgSeason.fetchClient({
                        orgSeason: newItem
                      }),
                    onError: e => {
                      openErrorToast(translate({ defaultMessage: "Unable to save changes to registration due date" }));
                      console.error(e);
                    },
                    render: a => (
                      <PrettyCoolDateInput
                        value={a.item.registrationDueDateMS ? moment(a.item.registrationDueDateMS).toDate() : undefined}
                        minDate={new Date(a.item.startDateMS)}
                        maxDate={new Date(a.item.endDateMS)}
                        onChange={newVal => {
                          const registrationDueDateMS = moment(newVal).endOf("day");
                          if (newVal && registrationDueDateMS.isAfter(moment(a.item.startDateMS))) {
                            a.setItem({ registrationDueDateMS: registrationDueDateMS.valueOf() });
                          } else {
                            openErrorToast(translate({ defaultMessage: "Registration due date must be after start date!" }));
                          }
                        }}
                      />
                    )
                  }
                : undefined
            }
          ]}
          getItemKey={item => {
            return item.id;
          }}
          filters={[
            {
              filterComponent: (
                <CoolTextInput
                  placeholder={translate({ defaultMessage: "Filter..." })}
                  value={searchInput}
                  onChange={newVal => {
                    setSearchInput(newVal ?? "");
                  }}
                />
              ),
              onFilter(items) {
                return searchInput
                  ? items.filter(item =>
                      item.name.toLowerCase().replaceAll(" ", "").includes(searchInput.toLowerCase().replaceAll(" ", ""))
                    )
                  : items;
              }
            },
            {
              filterComponent: (
                <CoolCheckboxInput
                  value={showArchived}
                  label={translate({ defaultMessage: "Show Archived" })}
                  labelType="inside"
                  onChange={newVal => {
                    setShowArchived(newVal);
                  }}
                />
              ),
              onFilter(items) {
                if (showArchived) {
                  return items;
                }
                return items.filter(item => !item.archivedAtMS);
              }
            }
          ]}
        />
      </div>
    </View>
  );
}
