import { Typography, Box } from "@material-ui/core";
import { View } from "react-native-web";
import _ from "lodash";
import { Org, OrgRegistrationPlayerSummary, Team } from "@ollie-sports/models";
import { CenteredLoader } from "../../components/CenteredLoader";
import { translate } from "@ollie-sports/i18n";
import { useParams } from "react-router-dom";
import { useOrg } from "../../hooks/useOrg";
import { ShadowView } from "../../components/ShadowView";
import CoolerTable, { CoolerTableColumnDef } from "../../components/CoolerTable";
import { TableSectionWrapper } from "./components/TableSectionWrapper";
import { BackButton } from "../../components/BackButton";
import { getBifrost } from "../../services/bifrost.service";
import { usePersistentState } from "../../utils/usePersistentState";
import { CoolSelectInput } from "../../components/Inputs/CoolSelectInput";
import { useOrgSeasons } from "../../hooks/useOrgSeasons";
import { useOrgTeams } from "../../hooks/useOrgTeams";
import { formatMoneyCentsToDollarCentPrettyString } from "@ollie-sports/core";
import { useState } from "react";
import { CoolMultiSelectInput } from "../../components/Inputs/CoolMultiSelectInput";
import { CoolTextInput } from "../../components/Inputs/CoolTextInput";
import { useTeam } from "../../hooks/useTeam";
import { useOrgCurrentSeasonId } from "../../utils/useOrgCurrentSeasonId";

export default function OrgReportsRegistrationTeamSummary() {
  const params: any = useParams();
  const orgId = params.orgId;
  const teamId = params.teamId;

  const { org, isLoading: isLoadingOrg } = useOrg({ orgId });
  const { team, isLoading: isLoadingTeam } = useTeam({ teamId });

  return (
    <Box px={3} py={2} display="flex" style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        {isLoadingOrg || isLoadingTeam ? (
          <CenteredLoader />
        ) : org && team ? (
          <OrgReportsRegistrationTeamSummaryInner org={org} team={team} />
        ) : (
          <Typography>{translate({ defaultMessage: "Failed to load data" })}</Typography>
        )}
      </View>
    </Box>
  );
}

function OrgReportsRegistrationTeamSummaryInner(p: { org: Org; team: Team }) {
  const [selectedOrgSeasonId, setSelectedOrgSeasonId] = useOrgCurrentSeasonId(p.org.id);

  const orgSeasons = useOrgSeasons({ orgId: p.org.id }) ?? [];

  const { data: reportData } = getBifrost().reports__server__getOrgRegistrationTeamSummary.useServer(
    {
      orgId: p.org.id,
      orgSeasonId: selectedOrgSeasonId,
      teamId: p.team.id
    },
    { enabled: !!selectedOrgSeasonId }
  );

  const playerBundleIds = reportData?.map(a => a.playerBundleId) ?? [];

  const { data: prettyPlayerBundles } = getBifrost().playerBundle__server__getPrettyPlayerBundles.useServer({
    ids: playerBundleIds
  });

  const allLineItemKeys = reportData?.reduce((acc, val) => {
    Object.keys(val.lineItems ?? {}).map(lineItemKey => {
      acc[lineItemKey] = true;
    });
    return acc;
  }, {} as Record<string, true>);

  const columns: CoolerTableColumnDef<OrgRegistrationPlayerSummary>[] = [
    {
      label: translate.common.Player,
      getValue(item) {
        const ppb = prettyPlayerBundles?.find(a => a.playerBundle.id === item.playerBundleId);
        if (ppb) {
          return `${ppb.derived.accountInfo.firstName} ${ppb.derived.accountInfo.lastName}`;
        }
        return "item.teamName;";
      },
      sortItems(items, dir) {
        return _.orderBy(items, a => a.teamName, dir);
      }
    },
    ...Object.keys(allLineItemKeys ?? {}).map(lineItemKey => {
      return {
        label: lineItemKey,
        getValue(item: OrgRegistrationPlayerSummary) {
          return formatMoneyCentsToDollarCentPrettyString(item.lineItems?.[lineItemKey]?.amountCents ?? 0);
        },
        getValueForTotalRow(items: OrgRegistrationPlayerSummary[]) {
          return formatMoneyCentsToDollarCentPrettyString(
            _.sum(
              items.map(item => {
                return item.lineItems?.[lineItemKey]?.amountCents ?? 0;
              })
            )
          );
        }
      };
    }),
    {
      label: translate({ defaultMessage: "Coupons" }),
      getValue(item) {
        return formatMoneyCentsToDollarCentPrettyString(item.couponAmountCents);
      },
      sortItems(items, dir) {
        return _.orderBy(items, a => a.couponAmountCents, dir);
      },
      getCellCustomClassName(item) {
        return "text-green-500";
      },
      getValueForTotalRow(items) {
        return formatMoneyCentsToDollarCentPrettyString(_.sum(items.map(item => item.couponAmountCents)));
      }
    },
    {
      label: translate({ defaultMessage: "Coupon Code" }),
      getValue(item) {
        return item.couponCode;
      },
      getCellCustomClassName(item) {
        return "text-green-500";
      }
    },
    {
      label: translate({ defaultMessage: "Total Invoiced Amount" }),
      getValue(item) {
        return formatMoneyCentsToDollarCentPrettyString(item.totalDueCents);
      },
      sortItems(items, dir) {
        return _.orderBy(items, a => a.totalDueCents, dir);
      },
      getCellCustomClassName(item) {
        return "font-bold";
      },
      getValueForTotalRow(items) {
        return formatMoneyCentsToDollarCentPrettyString(_.sum(items.map(item => item.totalDueCents)));
      }
    },
    {
      label: translate({ defaultMessage: "Credits" }),
      getValue(item) {
        return formatMoneyCentsToDollarCentPrettyString(item.creditAmountCents);
      },
      sortItems(items, dir) {
        return _.orderBy(items, a => a.creditAmountCents, dir);
      },
      getCellCustomClassName(item) {
        return "text-green-500";
      },
      getValueForTotalRow(items) {
        return formatMoneyCentsToDollarCentPrettyString(_.sum(items.map(item => item.creditAmountCents)));
      }
    },
    {
      label: translate({ defaultMessage: "Amount Paid" }),
      getValue(item) {
        return formatMoneyCentsToDollarCentPrettyString(item.defaultPaidAmountCents);
      },
      sortItems(items, dir) {
        return _.orderBy(items, a => a.defaultPaidAmountCents, dir);
      },
      getValueForTotalRow(items) {
        return formatMoneyCentsToDollarCentPrettyString(_.sum(items.map(item => item.defaultPaidAmountCents)));
      }
    },
    {
      label: translate({ defaultMessage: "Balance" }),
      getValue(item) {
        return formatMoneyCentsToDollarCentPrettyString(item.remainingDueCents);
      },
      sortItems(items, dir) {
        return _.orderBy(items, a => a.remainingDueCents, dir);
      },
      getCellCustomClassName(item) {
        return "font-bold";
      },
      getValueForTotalRow(items) {
        return formatMoneyCentsToDollarCentPrettyString(_.sum(items.map(item => item.remainingDueCents)));
      }
    }
  ];

  // const columnHeaders = columns.filter(c => c.label !== translate.common.Name).map(c => c.label);

  return (
    <View style={{ flex: 1 }}>
      <BackButton />
      <View>
        <h1 className="flex-1 text-2xl sm:text-4xl mt-4">{p.team.name}</h1>
        <div className="inline-block">
          <CoolSelectInput
            label={translate.common.Season}
            containerStyle={{ marginTop: 30 }}
            placeholder={translate({ defaultMessage: "Select Season" })}
            value={selectedOrgSeasonId}
            inputProps={{
              style: { fontSize: 20, height: 60, minWidth: 280, width: "auto" }
            }}
            options={orgSeasons
              .filter(os => !os.archivedAtMS)
              .map(os => {
                return {
                  label: os.name,
                  value: os.id
                };
              })}
            onChange={newVal => {
              setSelectedOrgSeasonId(newVal);
            }}
          />
        </div>
      </View>
      {selectedOrgSeasonId ? (
        <CoolerTable
          style={{ marginTop: 16 }}
          items={reportData ?? []}
          paginationOptions={{
            defaultPageSize: 25,
            pageSizeOptions: [25, 50, 100],
            persistenceKey: "org-reports-registration-team-summary"
          }}
          columnDefs={columns}
          getItemKey={item => item.playerBundleId}
        />
      ) : null}
    </View>
  );
}
