import { Typography } from "@material-ui/core";
import _ from "lodash";
import {
  OrgInvoice,
  OrgInvoiceChild,
  OrgInvoiceParent,
  OrgPayment,
  OrgPaymentInvoiceCredit,
  OrgPaymentType,
  OrgSettings,
  PlayerBundle
} from "@ollie-sports/models";
import { getCurrentLocale, translate } from "@ollie-sports/i18n";
import {
  COLORS,
  formatMoneyCentsToDollarCentPrettyString,
  getOrgPaymentDetails,
  getPaymentMethodString
} from "@ollie-sports/core";
import { useEffect, useRef, useState } from "react";
import { Form, PrettyCoolSelectInput, PrettyCoolTextInputWithLabel } from "../../components/Form";
import { openModal } from "../../components/modals/imperativeModal";
import { FullScreenModal } from "../../components/modals/getFullscreenModal";
import { StyledText } from "../../components/StyledText";
import { View } from "react-native-web";
import { openErrorToast, openSuccessToast } from "../../utils/openErrorToast";
import { getBifrost } from "../../services/bifrost.service";
import { useAccounts } from "../../hooks/useAccounts";
import { CoolSelectInput } from "../../components/Inputs/CoolSelectInput";
import { player } from "@ollie-sports/core/dist/compute";
import { orgInvoice__server__payIndividualOrgInvoiceChild } from "@ollie-sports/core/dist/api";
import { getCurrentUserAccountId } from "../../hooks/commonDataUtils";
import { CoolCheckboxInput } from "../../components/Inputs/CoolCheckboxInput";

type PropsForParentInvoiceCredit = {
  orgInvoice: OrgInvoice;
  remainingAmountCents: number;
  onComplete: () => Promise<void>;
  playerBundle: PlayerBundle;
  orgPayments: OrgPayment[];
  parentOrgInvoice: OrgInvoiceParent;
  orgPaymentInvoiceCredits: OrgPaymentInvoiceCredit[];
  feeDetails: OrgSettings["customFeeDetails"];
  futureInvoices: OrgInvoice[];
};

export function openOrgPaymentInvoiceChargeNowModal(p: PropsForParentInvoiceCredit) {
  return new Promise<string | undefined>(res => {
    const modal = openModal({
      body: (
        <OrgPaymentInvoiceChargeNowModal
          {...p}
          onRequestDismiss={() => {
            modal.close();
          }}
        />
      )
    });
  });
}

function OrgPaymentInvoiceChargeNowModal(p: PropsForParentInvoiceCredit & { onRequestDismiss: () => void }) {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState("");
  const [originalPaymentMethodId, setOriginalPaymentMethodId] = useState("");
  const hasLoaded = useRef(false);
  const [verificationText, setVerificationText] = useState("");
  const [isUpdatingPaymentMethod, setIsUpdatingPaymentMethod] = useState(false);

  const accountIds = Object.keys(p.playerBundle.managingAccounts ?? {});
  const { data: allPaymentMethods } = getBifrost().accountSecret__server__getPaymentMethodsForAccountIds.useServer(
    { accountIds },
    { enabled: !!accountIds.length }
  );

  const { accounts } = useAccounts({ accountIds });

  const playerName = `${p.playerBundle.virtualAthleteAccount.firstName.trim()} ${p.playerBundle.virtualAthleteAccount.lastName.trim()}`;

  const isScheduledPayment =
    p.orgInvoice.autoChargeDateMS && !p.orgInvoice.thisInvoicePaidInFullDateMS
      ? p.orgInvoice.autoChargeDateMS > Date.now()
      : false;

  useEffect(() => {
    if (allPaymentMethods?.length && !hasLoaded.current) {
      const accountIdScheduledToPay =
        ("accountIdScheduledToPay" in p.orgInvoice && p.orgInvoice.accountIdScheduledToPay) ||
        p.orgPayments.find(a => a.status === "failed" && a.invoiceId === p.orgInvoice.id && a.accountId) ||
        p.futureInvoices.map(a => ("accountIdScheduledToPay" in a ? a.accountIdScheduledToPay : false)).filter(a => a)[0];

      const defaultPaymentMethod = allPaymentMethods.find(a =>
        accountIdScheduledToPay ? accountIdScheduledToPay && !!a.isDefault : null
      );

      if (defaultPaymentMethod) {
        setSelectedPaymentMethodId(defaultPaymentMethod.id);
        setOriginalPaymentMethodId(defaultPaymentMethod.id);
        hasLoaded.current = true;
      }
    }
  }, [(allPaymentMethods ?? []).map(a => a.id).join("")]);
  const selectedPaymentMethodData = allPaymentMethods?.find(a => a.id === selectedPaymentMethodId);

  const orgPaymentDetails = getOrgPaymentDetails({
    orgInvoice: p.orgInvoice,
    parentOrgInvoice: p.parentOrgInvoice,
    orgPaymentInvoiceCredits: p.orgPaymentInvoiceCredits,
    feeDetails: p.feeDetails,
    paymentMethodType: selectedPaymentMethodData?.type
  });

  const paymentMethodAccount = accounts.find(acc => acc.id === selectedPaymentMethodData?.accountId);

  return (
    <Form
      children={isFormValid => {
        return (
          <FullScreenModal
            title={translate({ defaultMessage: "Process Payment" })}
            bottomButton={{
              title:
                orgPaymentDetails.type === "scheduled"
                  ? `${translate(
                      { defaultMessage: "Pay {amount}" },
                      {
                        amount: formatMoneyCentsToDollarCentPrettyString(
                          orgPaymentDetails.baseAmountDueCents +
                            orgPaymentDetails.estimatedFeesAmount +
                            orgPaymentDetails.lateFeeAmountDueCents
                        )
                      }
                    )}`
                  : "",
              enabled: isFormValid,
              onPress: async () => {
                setIsLoading(true);
                if (!selectedPaymentMethodData || orgPaymentDetails.type !== "scheduled") {
                  return;
                }
                try {
                  const { data: res } = await getBifrost().orgInvoice__server__payIndividualOrgInvoiceAsOrgAdmin.fetchServer({
                    isAutoPayment: false,
                    locale: getCurrentLocale(),
                    orgInvoice: p.orgInvoice,
                    accountIdToCharge: selectedPaymentMethodData.accountId,
                    selfAccountId: getCurrentUserAccountId(),
                    manualTriggerMS: Date.now(),
                    nonDefaultPaymentMethodIdToUse: selectedPaymentMethodData.id,
                    todayPaymentDetails: {
                      baseAmountDueCents: orgPaymentDetails.baseAmountDueCents - orgPaymentDetails.creditAmountCents,
                      otherFeesAmountDueCents: orgPaymentDetails.estimatedFeesAmount,
                      lateFeeAmountDueCents: orgPaymentDetails.lateFeeAmountDueCents
                    }
                  });
                  if (selectedPaymentMethodId !== originalPaymentMethodId && isUpdatingPaymentMethod) {
                    await getBifrost().orgInvoice__server__updatePaymentMethodForFutureInvoices.fetchServer({
                      orgInvoiceIds: p.futureInvoices.map(oi => oi.id),
                      paymentMethodData: selectedPaymentMethodData
                    });
                  }
                  if (res.type === "success") {
                    await new Promise(res => setTimeout(res, 1000));
                    await p.onComplete();
                    openSuccessToast();
                    p.onRequestDismiss();
                  } else if (res.type === "error") {
                    setErrorMsg(res.prettyErrorReason);
                  } else {
                    setErrorMsg(res.prettyFailureReason);
                  }
                } catch (e) {
                  setErrorMsg(
                    translate({
                      defaultMessage:
                        "There was a problem processing the payment. Please try again or contact support@olliesports.com"
                    })
                  );
                }
                setIsLoading(false);
              }
            }}
            onRequestDismiss={() => {
              p.onRequestDismiss();
            }}
          >
            <div>
              <div>
                <div className="mb-1 leading-tight">
                  <div className="font-bold">{`${translate({ defaultMessage: "Player Name" })}`}</div>
                  <div>{playerName}</div>
                </div>
                <div className="mb-1 leading-tight">
                  <div className="font-bold">
                    {translate({
                      defaultMessage: "Charge Amount"
                    })}
                  </div>
                  <div>{formatMoneyCentsToDollarCentPrettyString(p.remainingAmountCents)}</div>
                </div>
                {isScheduledPayment ? (
                  <>
                    <div className="mt-4">
                      {translate({
                        defaultMessage:
                          "You MUST have permission from the parents to process this scheduled payment. Please type the player's name below to confirm you have received permission."
                      })}
                    </div>
                    <PrettyCoolTextInputWithLabel
                      style={{ marginTop: 16 }}
                      label={translate({ defaultMessage: "Confirm Player Name" })}
                      onChange={newVal => {
                        setVerificationText(newVal ?? "");
                      }}
                      validate={val => {
                        if (!val) {
                          return translate.common.IsRequired;
                        }
                        if (val.toLowerCase().replace(" ", "") !== playerName.toLowerCase().replace(" ", "")) {
                          return translate({ defaultMessage: "Name doesn't match" });
                        }
                        return "";
                      }}
                      placeholder={playerName}
                      value={verificationText}
                    />
                  </>
                ) : null}

                <PrettyCoolSelectInput
                  containerStyle={{ marginTop: 16 }}
                  label={translate.common.PaymentMethod}
                  onChange={newVal => {
                    setSelectedPaymentMethodId(newVal);
                  }}
                  isRequired
                  value={selectedPaymentMethodId}
                  options={
                    allPaymentMethods?.map(a => {
                      return {
                        label: getPaymentMethodString({ paymentMethod: a, includeName: true, locale: getCurrentLocale() }),
                        value: a.id
                      };
                    }) ?? []
                  }
                />
                {p.futureInvoices.length && selectedPaymentMethodId !== originalPaymentMethodId ? (
                  <CoolCheckboxInput
                    infoTooltip={
                      paymentMethodAccount
                        ? translate(
                            { defaultMessage: "Note: This will update the default payment method for {accountName}" },
                            {
                              accountName: `${paymentMethodAccount.firstName} ${paymentMethodAccount.lastName}`
                            }
                          )
                        : undefined
                    }
                    label={translate({ defaultMessage: "Assign this payment method to all future payments for this invoice?" })}
                    labelType="inside"
                    onChange={newVal => {
                      setIsUpdatingPaymentMethod(newVal);
                    }}
                    value={isUpdatingPaymentMethod}
                  />
                ) : null}
              </div>
              {errorMsg ? <Typography style={{ color: COLORS.red, marginTop: 16 }}>{errorMsg}</Typography> : null}
            </div>
          </FullScreenModal>
        );
      }}
    />
  );
}
